/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';

import Input from '../components/Input';
import InputError from '../components/InputError';
import Button from '../components/Button';
import LeftHeroContent from '../components/LeftHeroContent';

import '../styles/SignUp.scss';

interface FormData {
    fullName: string;
    email: string;
}

const SignUp: React.FC = () => {
    const [awaitingConfirmEmail, setAwaitingConfirmEmail] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [termsChecked, setTermsChecked] = useState<boolean>(false);
    const [showTermsError, setShowTermsError] = useState<boolean>(false);

    const {
        register, formState: { errors, isValid }, handleSubmit, setError, trigger,
    } = useForm<FormData>({ mode: 'onBlur' }); // Validação ao sair do campo

    const onSubmit = useCallback(async (data: FormData) => {
        if (!termsChecked) {
            setShowTermsError(true);
            return; // Impede o envio se a checkbox não estiver marcada
        }

        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API}/api/subscribers`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: {
                        name: data.fullName,
                        email: data.email,
                    },
                }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                setError('email', {
                    type: 'error',
                    message: errorData.error.message || 'Erro ao cadastrar. Verifique seus dados e tente novamente.',
                });
            } else {
                setAwaitingConfirmEmail(true);
            }
        } catch (error) {
            setError('email', {
                type: 'error',
                message: 'Erro inesperado. Tente novamente mais tarde.',
            });
        } finally {
            setLoading(false);
        }
    }, [setError, termsChecked]);

    const handleFormSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const isFormValid = await trigger(); // Verifica os campos do formulário

        if (!isFormValid || !termsChecked) {
            setShowTermsError(!termsChecked); // Exibe erro da checkbox se não estiver marcada
        } else {
            handleSubmit(onSubmit)();
        }
    };

    return (
        <div id="sign-up-page" className="signin-signup-view">
            <div className="row-wrapper">
                <LeftHeroContent />
                <main>
                    {awaitingConfirmEmail ? (
                        <>
                            <span className="title">Bem-vindo ao Pixel Init!</span>
                            <span className="tip">
                                Sua inscrição foi realizada com sucesso.
                                Verifique sua caixa de entrada para mais informações.
                            </span>
                            <span className="tipAux">
                                Enquanto isso, junte-se à nossa <strong>comunidade exclusiva no WhatsApp</strong> e
                                fique conectado com outros participantes, além de receber informações em primeira mão.
                                Nossas comunicações se darão tanto pelo email quanto pela comunidade!
                            </span>
                            <Button
                                bgColor="#59ce72"
                                onClick={() => window.open('https://chat.whatsapp.com/DYSskWyFxniJFslqDXopWK', '_blank')}
                            >
                                Entre na nossa comunidade
                            </Button>
                        </>
                    ) : (
                        <>
                            <span className="title">Faça a sua inscrição abaixo.</span>
                            <form onSubmit={handleFormSubmit}>
                                <Input
                                    {...register('fullName', { required: true })}
                                    hasError={!!errors.fullName}
                                    type="text"
                                    placeholder="Nome completo"
                                />
                                <InputError
                                    visible={errors.fullName?.type === 'required'}
                                    message="Insira seu nome completo"
                                />

                                <Input
                                    {...register('email', {
                                        required: true,
                                        pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                    })}
                                    hasError={!!errors.email}
                                    autoComplete="on"
                                    type="email"
                                    placeholder="E-mail"
                                />
                                <InputError
                                    visible={errors.email?.type === 'required'}
                                    message="Insira um e-mail"
                                />
                                <InputError
                                    visible={errors.email?.type === 'pattern'}
                                    message="Insira um e-mail válido"
                                />
                                <InputError
                                    visible={errors.email?.type === 'error'}
                                    message={errors.email?.message || ''}
                                />

                                {/* Checkbox para aceitar os termos */}
                                <div className="terms">
                                    <input
                                        type="checkbox"
                                        id="agreeToTerms"
                                        onChange={(e) => {
                                            setTermsChecked(e.target.checked);
                                            if (e.target.checked) {
                                                setShowTermsError(false); // Esconder o erro quando marcado
                                            }
                                        }}
                                        aria-invalid={showTermsError}
                                    />
                                    <label htmlFor="agreeToTerms">
                                    {' '}Li e aceito os{' '}
    <a href="/terms" target="_blank" rel="noopener noreferrer"> Termos de Uso </a>{' '}
    e a{' '}
    <a href="/privacy" target="_blank" rel="noopener noreferrer"> Política de Privacidade </a>
                                    </label>

                                </div>
                                {showTermsError && (
                                    <InputError
                                        visible={showTermsError}
                                        message="Você deve aceitar os termos de uso e a política de privacidade"
                                    />
                                )}

                                <Button
                                    bgColor={loading || !isValid || !termsChecked ? '#ccc' : 'secondary'}
                                    type="submit"
                                    disabled={loading || !isValid || !termsChecked}
                                    aria-busy={loading}
                                >
                                    {loading ? (
                                        <span className="loading-spinner" aria-label="Carregando..."></span>
                                    ) : (
                                        'vamos lá'
                                    )}
                                </Button>
                            </form>
                        </>
                    )}
                </main>
            </div>
        </div>
    );
};

export default SignUp;
