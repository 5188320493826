/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { Link } from 'react-router-dom';
import { useDateReveal } from '../contexts/DateRevealContext';
import '../styles/SignUpBanner.scss';

const SignUpBannerEnd: React.FC = () => {
  const { isDateRevealed } = useDateReveal();

  // Define as datas reais se a data estiver revelada
  const displayDates = isDateRevealed
    ? '1, 2 e 3 de outubro'
    : '[REDACTED 👀] de [REDACTED 👀]';

  return (
    <div id="sign-up-banner-end-wrapper-banner">
      <div className="sign-up-banner" id="end">
        <div>
          <h1>
            se interessou? então não deixa de realizar a sua
            <span id="secondary-color"> inscrição</span>
            !
          </h1>
        </div>
        <p>
          nos dias{' '}
          {displayDates}
          , temos um encontro no{' '}
          <a
            href="https://www.youtube.com/@pixelinit4023/streams"
            target="_blank"
            rel="noopener noreferrer"
          >
            YouTube
          </a>
          !
        </p>
        <div className="actions">
          <Link
            style={{
              pointerEvents: isDateRevealed ? 'auto' : 'none',
              opacity: isDateRevealed ? 1 : 0.5,
            }}
            to="/signup"
          >
            inscreva-se
          </Link>
          {!isDateRevealed && (
            <p style={{ marginTop: 20 }}>
              *as inscrições ainda não estão abertas.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default SignUpBannerEnd;
