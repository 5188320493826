/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable no-trailing-spaces */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */

//! TODO
// 1 - Botão de voltar para a página anterior (home do pixelinit)
// pode ser na logo ou em um botão separado
// 2 - Textos do https://docs.google.com/document/d/1UpFe_pH4nRICmNT2NBTmOG9Mvw3Xnm_YOBEp7yW3-HA/edit
// Tenta seguir bem a estrutura do documento, acho que tá bem legível assim.
// 3 - Em alguns lugares do documento tem links, por exemplo o email do pixelinit, lembra deixar ele em destaque.

// Sobre UI - pode se basear bem no que esta na home do pixelinit, acho que tá bem bonito
// Estilos pode fazer em css normal mesmo
// Lembra de fazer responsivo, mas como é tudo texto acho que vai ser bem tranquilo
// o CSS do SignUp, ele ta em /styles/SignUp.scss é um bom exemplo de como tu pode seguir

// Possivelmente vais encontrar algum erro de linting, mas o terminal geralmente mostra bem o que é necessário corrigir
// Se tiveres alguma dúvida, podes me perguntar

import React from 'react';
import '../styles/privacy.styles.css';
import Footer from '../components/Footer';

const Privacy = () => (
  <>
  <div className="privacy-container">
    <div className="text">
      <h1 className="privacy-title">Privacidade</h1>
      <p className="privacy-text">
      Bem-vindo à nossa política de privacidade. Nesta página, explicamos como lidamos com suas informações e 
      garantimos a proteção dos seus dados. Por favor, leia atentamente e entre em contato se tiver dúvidas.
      </p>

      <h2>1. Informações Coletadas
      </h2>

      <p>    Coletamos as seguintes informações dos participantes: nome e email.
      </p>

      <h2>2. Finalidade do Uso dos Dados
      </h2>

      <p> Os dados coletados serão utilizados para:
        <ul>
          <li>        Envio de informações sobre o evento (cronograma, lembretes, atualizações);
          </li>
          <li>        Envio de materiais de palestras e conteúdos extras;
          </li>
          <li>        Comunicação de oportunidades futuras relacionadas ao evento ou à Pixel.
          </li>
        </ul>
      </p>
      
      <h2>3. Compartilhamento com Patrocinadores</h2>

<p> Os dados (nome e email) poderão ser compartilhados com possíveis patrocinadores do evento, apenas com o consentimento do participante e para fins de contato comercial, marketing e divulgação de produtos ou serviços. O participante pode optar por não compartilhar seus dados com os patrocinadores ao entrar em contato com a organização, através do email pixelinit@ejpixel.com.br.
</p>

      <h2>4. Retenção e Exclusão de Dados
      </h2>

      <p>    Os dados pessoais dos inscritos serão armazenados em nosso banco de dados por um período de até 6 meses após o término do evento. Após esse período, os dados serão permanentemente excluídos, conforme as exigências da LGPD.
      </p>
    
      <h2>5. Segurança
      </h2>

      <p>    Tomamos medidas de segurança adequadas para proteger as informações pessoais contra acessos não autorizados, uso indevido e violação de dados. Entretanto, reconhecemos que não é possível garantir a segurança absoluta dos dados transmitidos pela internet.
      </p>
     
      <h2>6. Direitos dos Participantes
      </h2>

      <p>
        O participante tem o direito de:
       <ul>
        <li>        Solicitar acesso aos dados pessoais armazenados;
        </li>
        <li>        Retificar qualquer informação incorreta;
        </li>
        <li>        Solicitar a exclusão de seus dados a qualquer momento;
        </li>
        <li>        Revogar o consentimento para o compartilhamento de dados com patrocinadores.
        </li>
       </ul>
</p>
      
      <h2>7. Alterações na Política de Privacidade
      </h2>

      <p>    Esta Política de Privacidade pode ser atualizada periodicamente. Qualquer alteração será comunicada através de nossos canais oficiais.
      </p>
      
      <h2>8. Consentimento
      </h2>

      <p>    Ao se inscrever no evento, o participante declara estar ciente e concordar com os termos de uso de seus dados pessoais, conforme descrito nesta Política de Privacidade.
      </p>

    </div>
    
  </div>
    <Footer />
    </>
);

export default Privacy;
