import React, {
 createContext, useContext, useEffect, useState,
} from 'react';

// Cria o contexto
const DateRevealContext = createContext<{ isDateRevealed: boolean; loading: boolean }>({
  isDateRevealed: false,
  loading: true,
});

// Hook para usar o contexto em qualquer componente
export const useDateReveal = () => useContext(DateRevealContext);

export const DateRevealProvider: React.FC = ({ children }) => {
  const [isDateRevealed, setIsDateRevealed] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchIsDateRevealed = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API}/api/is-date-revealed`);
        const data = await response.json();

        // Verifica a estrutura da resposta e acessa o campo 'check'
//        console.log('API response:', data);

        // Verifica se o campo "check" dentro de "attributes" está presente
        setIsDateRevealed(data?.data?.attributes?.check || false);
        setLoading(false);
      } catch (error) {
        console.error('Erro ao buscar o estado de revelação da data:', error);
        setLoading(false);
      }
    };
    fetchIsDateRevealed();
  }, []);

  return (
    <DateRevealContext.Provider value={{ isDateRevealed, loading }}>
      {children}
    </DateRevealContext.Provider>
  );
};
