/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable no-trailing-spaces */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */

//! TODO
// 1 - Botão de voltar para a página anterior (home do pixelinit)
// pode ser na logo ou em um botão separado
// 2 - Textos do https://docs.google.com/document/d/1UpFe_pH4nRICmNT2NBTmOG9Mvw3Xnm_YOBEp7yW3-HA/edit
// Tenta seguir bem a estrutura do documento, acho que tá bem legível assim.
// 3 - Em alguns lugares do documento tem links, por exemplo o email do pixelinit, lembra deixar ele em destaque.

// Sobre UI - pode se basear bem no que esta na home do pixelinit, acho que tá bem bonito
// Estilos pode fazer em css normal mesmo
// Lembra de fazer responsivo, mas como é tudo texto acho que vai ser bem tranquilo
// o CSS do SignUp, ele ta em /styles/SignUp.scss é um bom exemplo de como tu pode seguir

// Possivelmente vais encontrar algum erro de linting, mas o terminal geralmente mostra bem o que é necessário corrigir
// Se tiveres alguma dúvida, podes me perguntar

import React from 'react';

// estilos do css :)
import '../styles/terms.styles.css';
import Footer from '../components/Footer';
import { FORGOT_PASSWORD } from '../constants/graphql/graphql';

const Terms = () => (
<>
  <div className="terms-container">
    <div className="text">
      <h1 className="terms-title">Termos de Serviço</h1>
      <p className="terms-text">
      Estes são os nossos termos de serviço. Ao utilizar nosso site, você concorda com os termos e condições descritos aqui. Por favor, leia com atenção antes de continuar a usar o site.
      </p>
      
      <h2>1. Aceitação dos Termos
      </h2>
      <p>    Ao se inscrever no evento Pixel Init, organizado pela Pixel, você concorda com estes Termos de Uso e com a Política de Privacidade. Caso não concorde com os termos, solicitamos que não conclua sua inscrição.
      </p>
      
      <h2>2. Participação no Evento
      </h2>
      <p>    A inscrição no evento é gratuita e dá direito ao participante a acessar as palestras e conteúdos oferecidos. A participação pode estar sujeita a limite de vagas.
      </p>
      
      <h2>3. Modificações no Evento
      </h2>
      <p>    Reservamo-nos o direito de alterar o cronograma, conteúdo ou palestrantes do evento sem aviso prévio. Qualquer modificação será comunicada através dos canais oficiais de comunicação.
      </p>
      
      <h2>4. Cancelamento da Inscrição
      </h2>
      <p>    O participante pode solicitar o cancelamento de sua inscrição a qualquer momento entrando em contato com a organização pelo email pixelinit@ejpixel.com.br.
      </p>

      <h2>5. Uso de Dados Pessoais
      </h2>
      <p>    Os dados pessoais fornecidos no ato da inscrição serão utilizados para fins de comunicação sobre o evento, envio de materiais relacionados e, quando autorizado pelo participante, para o compartilhamento com patrocinadores conforme especificado na Política de Privacidade.
      </p>
      
      <h2>6. Propriedade Intelectual
      </h2>
      <p>    Todo o conteúdo apresentado durante o evento é protegido por direitos autorais e não pode ser reproduzido ou redistribuído sem permissão prévia.
      </p>

      <h2>7. Limitação de Responsabilidade
      </h2>
      <p>    A organização não se responsabiliza por quaisquer problemas técnicos ou de conexão que possam impedir a participação no evento online.
      </p>
      
      <h2>8. Política de Cancelamento do Evento
      </h2>
      <p>    Em caso de cancelamento do evento por motivos imprevistos, os inscritos serão informados e, caso aplicável, o valor da inscrição será reembolsado.
      </p>
      
    </div>
    
  </div>
  <Footer />
  </>
);

export default Terms;
