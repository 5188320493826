import React, { useState, useEffect } from 'react';
import { useDateReveal } from '../contexts/DateRevealContext'; // Importa o contexto
import '../styles/SponsorsBanner.scss';
import NoAvatar from '../assets/images/no-avatar.png'; // Imagem padrão caso não haja imagem disponível

const SponsorsBanner: React.FC = () => {
    const [sponsors, setSponsors] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const { isDateRevealed } = useDateReveal();

    // Função para buscar os patrocinadores da API do Strapi
    useEffect(() => {
        const fetchSponsors = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API}/api/sponsors?populate=*`);
                const data = await response.json();
//                console.log('SPONSORS: dados recebidos da API:', data);

                if (data && data.data) {
                    setSponsors(data.data); // Define os dados dos patrocinadores
                } else {
                    console.error('Estrutura de dados inválida:', data);
                }
            } catch (error) {
                console.error('Erro ao buscar os patrocinadores:', error);
            } finally {
                setLoading(false);
            }
        };

        if (isDateRevealed) {
            fetchSponsors(); // Apenas faz a chamada à API se a data tiver sido revelada
        } else {
            setLoading(false); // Se a data não foi revelada, não carrega e mostra "Em breve"
        }
    }, [isDateRevealed]);

    if (loading) {
        return <div>Carregando patrocinadores...</div>;
    }

    // Se não houver patrocinadores ou a data não foi revelada, exibe "Em breve"
    if (!sponsors || sponsors.length === 0 || !isDateRevealed) {
        return <div>Em breve...</div>;
    }

    return (
        <div id="sponsors-banner-component">
            {sponsors.map((sponsor) => {
                const { attributes } = sponsor;
  //               console.log('Sponsor:', sponsor);

                // Verifica se o atributo de imagem está presente e acessível
// const imageUrl = attributes?.image?.data?.attributes?.url
//                    ? `${process.env.REACT_APP_API}${attributes.avatar.data.attributes.url}`
//                    : NoAvatar; // Se a imagem não existir, usa a imagem padrão

const imageUrl = attributes?.avatar?.data?.attributes?.url
  ? `${process.env.REACT_APP_API}${attributes.avatar.data.attributes.url}`
  : NoAvatar;

                const link = attributes?.link || '#'; // Se não houver link, define como '#'
                const name = attributes?.name || 'Patrocinador'; // Se não houver nome, usa 'Patrocinador'

                return (
                    <div key={sponsor.id} className="sponsor-item">
                        <a href={link} target="_blank" rel="noopener noreferrer">
                            <img
                                src={imageUrl}
                                alt={name}
                                onError={(e) => {
                                    // Se houver erro ao carregar a imagem, define a imagem padrão
                                    e.currentTarget.src = NoAvatar;
                                }}
                            />
                        </a>
                    </div>
                );
            })}
        </div>
    );
};

export default SponsorsBanner;
