import { gql } from '@apollo/client';

export const SIGN_IN = gql`
    mutation signIn($identifier: String!, $password: String!) {
        login(input: {
            identifier: $identifier,
            password: $password,
        }) {
            jwt
            user {
                id
                username
                email
                confirmed
                blocked
            }
        }
    }
`;

export const SIGN_UP = gql`
    mutation signUp($fullName: String!, $username: String!, $email: String!, $password: String!, $birthday: Date!, $inviter: String) {
        register(input: {
            fullName: $fullName,
            username: $username,
            email: $email,
            password: $password,
            birthday: $birthday,
            inviter: $inviter,
        }) {
            jwt
        }
    }
`;

export const FORGOT_PASSWORD = gql`
    mutation forgotPassword($email: String!) {
        forgotPassword(email: $email) {
            ok
        }
    }
`;

export const RESET_PASSWORD = gql`
    mutation resetPassword($code: String!, $password: String!, $passwordConfirmation: String!) {
        resetPassword(
            code: $code,
            password: $password,
            passwordConfirmation: $passwordConfirmation,
        ) {
            jwt
        }
    }
`;

export const ME = gql`
    query me {
        me {
            fullName
            avatar
            confirmed
            email
            is_pixelthon
            pixelthon_group {
                id
                users_permissions_users {
                    id
                }
            }
        }
    }
`;

export const DAY_LECTURES_LIST = gql`
    query DayLecturesList(
        $date_gt: String!,
        $date_lt: String!
    ) {
        lectures(
            sort: "date"
            where: {
                date_gt: $date_gt,
                date_lt: $date_lt
            }
        ){
            title
            description
            date
            end_date
            url
            category
            is_live
            youtube_link
            speakers {
                fullName
                url
                avatar{
                    url
                }
            }
        }
    }
`;

export const LECTURES_LIST = gql`
    query LecturesList {
        lectures(
            sort: "date"
        ){
            title
            description
            date
            end_date
            url
            category
            is_live
            youtube_link
            speakers {
                fullName
                url
                avatar{
                    url
                }
            }
        }
    }
`;

export const INVITED_FRIENDS = gql`
    query invitedFriends {
        invitedFriendsCount
    }
`;

export const SPEAKER = gql`
    query speaker(
        $url: String!
    ) {
        speakers(
            where: {
                url: $url
            }
        ) {
            id
            fullName
            description
            avatar {
                formats
            }
            email
            linkedin_link
            twitter_link
            youtube_link
            instagram_link
            lectures {
                title
                description
                date
                end_date
                url
                category
                is_live
                youtube_link
                speakers {
                    fullName
                    url
                    avatar{
                        url
                    }
                }
            }
        }
    }
`;

export const SPEAKERS = gql`
    query speakers {
        speakers(
            sort: "fullName"
        ) {
            id
            fullName
            description
            avatar {
                formats
            }
            email
            linkedin_link
            twitter_link
            youtube_link
            instagram_link
            lectures {
                title
                description
                date
                end_date
                url
                category
                is_live
                youtube_link
                speakers {
                    fullName
                    url
                    avatar{
                        url
                    }
                }
            }
        }
    }
`;
